import React, { useRef, useState } from "react";
import { CiLight } from "react-icons/ci";
import { MdDarkMode } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import useDarkMode from "../../hooks/useDarkMode";
import useUserInfo from "../../hooks/useUserInfo";
import Dropdown, { DropdownInterface, DropdownItemInterface } from "./Dropdown";
import { FaUser, FaCog, FaSignOutAlt } from "react-icons/fa";
import useOutsideClick from "../../hooks/useOutsideClick";
import { initAuth } from "../../clients/firebase";

const Navbar: React.FC = () => {
  const { email, firstName, lastName, username } = useUserInfo();
  const navigate = useNavigate();
  const firebaseAuth = initAuth();
  const profileRef = useRef(null);

  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [colorTheme, setTheme] = useDarkMode(); // Get the current URL path using useLocation hook
  const dropdownOutsideClickRef = useOutsideClick(() => toggleDropdown());
  const toggleDarkMode = () => {
    setTheme((prev) => (prev === "dark" ? "light" : "dark"));
  };

  const handleSignOut = () => {
    firebaseAuth.signOut();

    // Navigate to '/'
    navigate(0);
  };
  const toggleDropdown = () => {
    setIsDropdownVisible((prev) => !prev);
  };
  const dropdownItems: DropdownItemInterface[] = [
    {
      label: "My Profile",
      onClick: () => {
        navigate("/profile");
        toggleDropdown();
      },
      icon: "/assets/sidebar/dropdown/profile.svg",
    },
    {
      label: "Settings",
      onClick: () => console.log("Settings clicked"),
      icon: "/assets/sidebar/dropdown/settings.svg",
    },
    {
      label: "Sign Out",
      onClick: handleSignOut,
      icon: "/assets/sidebar/dropdown/logout.svg",
    },
  ];
  return (
    <nav
      className="w-max-max mx-auto flex h-16 flex-row items-center justify-between bg-white 
 p-2 duration-500 "
    >
      <div className="ml-auto inline-flex items-center gap-5 self-stretch ">
        <div className="relative inline-block text-left">
          <button
            onClick={toggleDropdown}
            className="flex flex-shrink-0 flex-grow-0 items-center justify-start gap-6"
          >
            <div className="relative flex h-10 w-10 flex-shrink-0 flex-grow-0 flex-col items-center justify-center gap-2.5 overflow-hidden rounded-[20px] border border-[#6839ee]/[0.16] bg-[#6839ee]/[0.16] p-2.5">
              <p className="flex-shrink-0 flex-grow-0 text-left text-lg font-medium text-[#7354f0]">
                {username ? username[0] : ""}
              </p>
            </div>
          </button>

          {isDropdownVisible && (
            <Dropdown
              items={dropdownItems}
              dropdownOutsideClickRef={dropdownOutsideClickRef}
            />
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
