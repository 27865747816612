import React, { lazy, Suspense } from "react";
import Layout from "./layout";
import SuspenseLoader from "./components/Loader/Suspense";
import PrivateRoutes from "./middleware/Authentification/PrivateRoutes";

const Loader = (Component: React.FC) => (props: any) => (
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);

const HomePage = Loader(lazy(() => import("./pages/Home")));
const MyProductsPage = Loader(lazy(() => import("./pages/MyProducts")));
const CreateProductMultiPage = Loader(
  lazy(() => import("./pages/CreateProductMulti")),
);
const LoginPage = Loader(lazy(() => import("./pages/Login")));
const LoginShopifyPage = Loader(lazy(() => import("./pages/LoginShopify")));
const Register = Loader(lazy(() => import("./pages/Register")));
const Integration = Loader(lazy(() => import("./pages/Integration")));
const ShopifyOnBoard = Loader(lazy(() => import("./pages/ShopifyOnBoard")));
const PrintifyOnBoard = Loader(lazy(() => import("./pages/PrintifyOnBoard")));

const UserOnBoard = Loader(lazy(() => import("./pages/UserOnBoard")));
const Status404 = Loader(lazy(() => import("./pages/Helpful/Error/404")));
const ComingSoon = Loader(lazy(() => import("./pages/Helpful/ComingSoon")));
const GenerateMockup = Loader(lazy(() => import("./pages/GenerateMockup")));
const CreateShopifyStore = Loader(
  lazy(() => import("./pages/CreateShopifyStore/index")),
);
const ProfilePage = Loader(lazy(() => import("./pages/Profile")));
const PlatformLearning = Loader(lazy(() => import("./pages/PlatformLearning")));
const PackagesPage = Loader(lazy(() => import("./pages/Packages")));
const ContactUs = Loader(lazy(() => import("./pages/ContactUs")));
const MyContent = Loader(lazy(() => import("./pages/MyContent")));
const LearningHub = Loader(lazy(() => import("./pages/LearningHub")));
const Onboarding = Loader(lazy(() => import("./pages/OnboardingInitial")));
const comingSoonPages = ["/mockups", "/trending", "/content"];

const routes = () => [
  {
    element: <PrivateRoutes />,
    children: [
      {
        element: <Layout />,
        children: [
          {
            path: "/packages",
            element: <PackagesPage />,
          },
          // {
          //   path: "/content",
          //   element: <MyContent />,
          // },
          {
            path: "/profile",
            element: <ProfilePage />,
          },
          {
            path: "/",
            element: <HomePage />,
          },
          {
            path: "/createProduct",
            element: <CreateProductMultiPage />,
          },
          // {
          //   path: "/mockups",
          //   element: <GenerateMockup />,
          // },
          {
            path: "/myProducts",
            element: <MyProductsPage />,
          },
          {
            path: "/integration",
            element: <Integration />,
          },
          {
            path: "/tutorials",
            element: <PlatformLearning />,
          },
          {
            path: "/onboarding",
            element: <Onboarding />,
          },
          // {
          //   path: "/shopifyOnBoard",
          //   element: <ShopifyOnBoard />,
          // },
          // {
          //   path: "/printifyOnBoard",
          //   element: <PrintifyOnBoard />,
          // },
          {
            path: "/storeBuilder",
            element: <CreateShopifyStore />,
          },
          {
            path: "/learningHub",
            element: <LearningHub />,
          },
          {
            path: "/contactUs",
            element: <ContactUs />,
          },
          {
            path: "*",
            element: comingSoonPages.includes(window.location.pathname) ? (
              <ComingSoon />
            ) : (
              <Status404 />
            ),
          },
        ],
      },
    ],
  },

  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/shopify/callback",
    element: <LoginShopifyPage />,
  },
  {
    path: "/register",
    element: <Register />,
  },
];

export default routes;
