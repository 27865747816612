import { useEffect, useState } from "react";
import { Outlet, Navigate, useNavigate, useLocation } from "react-router-dom";
import { initAuth } from "../../../clients/firebase";
import { onAuthStateChanged } from "firebase/auth";

const PrivateRoutes = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [hasViewedTutorial, setHasViewedTutorial] = useState<boolean>();
  const auth = initAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Listen for authentication state changes
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      console.log(user);
      if (user) {
        // User is signed in
        const storedUser = JSON.parse(localStorage.getItem("user") as any);
        if (storedUser) {
          setHasViewedTutorial(storedUser.hasViewedTutorial);
        }
        setIsAuthenticated(true);
      } else {
        // User is signed out
        setIsAuthenticated(false);
      }
      setLoading(false);
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [auth]);

  useEffect(() => {
    console.log(hasViewedTutorial);
    if (
      hasViewedTutorial !== undefined &&
      !hasViewedTutorial &&
      location.pathname !== "/packages"
    ) {
      navigate("/onboarding");
    }
  }, [hasViewedTutorial, location.pathname, navigate]);
  if (loading) {
    return <div>Loading...</div>; // Or any other loading indicator you prefer
  }
  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return <Outlet />;
};

export default PrivateRoutes;
